import { z } from "zod";

export const kpiConfigSchema = z.object({
  metric: z.string(),
  category: z.string(),
  roll: z.number(),
  label: z.string(),
  brand: z.string(),
  statement: z.string().optional(),
  audience: z.string().optional(),
  secondaryAudience: z.string().optional(),
});

export type KpiConfig = z.infer<typeof kpiConfigSchema>;

export const kpiSingleMetricsSchema = kpiConfigSchema.partial().merge(
  z.object({
    label: z.string(),
    statement: z.string(),
  }),
);

export type KpiSingleMetrics = z.infer<typeof kpiSingleMetricsSchema>;

export const kpiSummaryConfigSchema = kpiConfigSchema.merge(
  z.object({
    kpiMetrics: z.array(kpiSingleMetricsSchema),
  }),
);

export type KpiSummaryConfig = z.infer<typeof kpiSummaryConfigSchema>;

export const execSummaryConfigSchema = z.object({
  aiSummaryText: z.string().optional(),
  kpiSummary: z.array(kpiSummaryConfigSchema).optional(),
});

export type ExecSummaryConfig = z.infer<typeof execSummaryConfigSchema>;
