import { Divider, Tabs } from "@mantine/core";
import { useHash, useViewportSize } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import graphql from "babel-plugin-relay/macro";
import { ReactNode, Suspense, useCallback, useRef, useState } from "react";
import React from "react";
import { useLazyLoadQuery } from "react-relay";

import { AiSummary } from "../component/AiSummary";
import {
  DASHBOARD_TAB_HEADER_HEIGHT,
  DashboardTabHeader,
} from "../component/DashboardTabHeader";
import { LoadingSpinner } from "../component/LoadingSpinner";
import { NAVBAR_HEIGHT } from "../component/Navbar/Navbar";
import { SmallLoader } from "../component/SmallLoader";
import {
  DashboardLevelFilters,
  StandardDashboardFilterBar,
} from "../component/StandardDashboardFilterBar";
import { CodedUnpromptedMessagesBreakdown } from "../component/Visualisation/CodedUnpromptedMessagesBreakdown";
import { FunnelComparison } from "../component/Visualisation/FunnelComparison";
import { MultiFilterCommentBreakdown } from "../component/Visualisation/MultiFilterCommentBreakdown";
import { PromptedMessagesBreakdown } from "../component/Visualisation/PromptedMessagesBreakdown";
import { ScatterLine } from "../component/Visualisation/ScatterLine";
import { SnapshotComparison } from "../component/Visualisation/SnapshotComparison";
import { StackedVerticalBarGraph } from "../component/Visualisation/StackedVerticalBarGraph";
import { Timeline } from "../component/Visualisation/Timeline";
import { VerticalBarGraph } from "../component/Visualisation/VerticalBarGraph";
import { VisualisationProps } from "../component/Visualisation/types";
import { VisualisationConfig, VisualisationType } from "../types/Client";
import { assertUnreachable } from "../util/assertUnreachable";
import {
  copyPngToClipboard,
  downloadCsv,
  downloadPng,
  downloadXlsx,
  genAllTablesInCsvFormat,
  getHeightIncreaseToMaxScrolls,
} from "../util/download";
import { StandardDashboardTabQuery as StandardDashboardTabQueryType } from "./__generated__/StandardDashboardTabQuery.graphql";

type DashboardTabProps = {
  readonly chartTitle: string;
  readonly metric: string;
  readonly clientId: string;
  readonly visualisations: VisualisationConfig[];
  readonly aiSummary?: string;
};

export type DashboardLevelQueryFilters = {
  readonly clientId: string;
  readonly metric: string;
} & DashboardLevelFilters;

const StandardDashboardTabQuery = graphql`
  query StandardDashboardTabQuery(
    $clientId: String!
    $metric: String!
    $firstAudience: String
    $secondAudience: String
    $category: String
    $roll: Int
  ) {
    dashboardFilterOptions: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: $metric }
      distinctSelect: [
        "AUDIENCE1"
        "AUDIENCE_GROUP1"
        "AUDIENCE2"
        "AUDIENCE_GROUP2"
        "CATEGORY"
        "ROLL"
      ]
    ) {
      AUDIENCE1
      AUDIENCE_GROUP1
      AUDIENCE2
      AUDIENCE_GROUP2
      CATEGORY
      ROLL
    }
    downloadData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

/**
 * A dashboard tab component that displays data for a specific metric
 *
 * @param props the metric to be displayed
 * @returns a dashboard tab component
 */
export const StandardDashboardTab: React.FC<DashboardTabProps> = (
  props: DashboardTabProps,
) => {
  const { aiSummary, chartTitle, metric, clientId, visualisations } = props;

  const ref = useRef(null);
  const [screenshotHeightPadding, setScreenshotHeightPadding] = useState(0);

  const { height } = useViewportSize();
  const [hash, setHash] = useHash();

  const [dashboardFilters, setDashboardFilters] =
    useState<DashboardLevelFilters>({
      category: "none",
      roll: -1,
      firstAudience: "none",
      secondAudience: "none",
    });
  const [showAiSummary, setShowAiSummary] = useState<boolean>(false);

  const dashboardFiltersVariables: DashboardLevelQueryFilters = {
    clientId,
    metric,
    ...dashboardFilters,
  };

  const queryData = useLazyLoadQuery<StandardDashboardTabQueryType>(
    StandardDashboardTabQuery,
    dashboardFiltersVariables,
  );

  const cleanHash = (value: string): string => {
    return value.replace("#", "").replaceAll("%20", " ");
  };

  const getVisualisationComponent = useCallback(
    (
      type: VisualisationType,
      visualisationProps: VisualisationProps,
    ): ReactNode => {
      switch (type) {
        case VisualisationType.TIMELINE:
          return <Timeline {...visualisationProps} />;
        case VisualisationType.SCATTER_LINE:
          return <ScatterLine {...visualisationProps} />;
        case VisualisationType.PERCENTAGE_STACK:
          return (
            <SnapshotComparison
              variant="PercentageStack"
              {...visualisationProps}
            />
          );
        case VisualisationType.VERTICAL_BAR_GRAPH:
          return <VerticalBarGraph {...visualisationProps} />;
        case VisualisationType.STACKED_VERTICAL_BAR_GRAPH:
          return <StackedVerticalBarGraph {...visualisationProps} />;
        case VisualisationType.BRAND_EDGE_TRIANGLE:
          return (
            <SnapshotComparison
              variant="BrandEdgeTriangle"
              {...visualisationProps}
            />
          );
        case VisualisationType.FUNNEL_COMPARISON:
          return <FunnelComparison {...visualisationProps} />;
        case VisualisationType.PROMPTED_MESSAGES:
          return <PromptedMessagesBreakdown {...visualisationProps} />;
        case VisualisationType.UNPROMPTED_MESSAGES:
          return <CodedUnpromptedMessagesBreakdown {...visualisationProps} />;
        case VisualisationType.NPS_COMMENTS:
          return <MultiFilterCommentBreakdown {...visualisationProps} />;
        default:
          assertUnreachable(type);
      }
    },
    [],
  );

  const getCurrentSelectedTab = useCallback(() => {
    return visualisations.find((vis) => vis.title === cleanHash(hash))
      ? cleanHash(hash)
      : visualisations[0].title;
  }, [hash, visualisations]);

  const downloadImage = useCallback(
    async (saveTo: "download" | "clipboard") => {
      const component = ref.current;
      if (!component) {
        return;
      }
      try {
        const heightIncrease = getHeightIncreaseToMaxScrolls(component);
        setScreenshotHeightPadding(heightIncrease + 5);
        // wait for the height to update
        await new Promise((resolve) => setTimeout(resolve, 500));
        switch (saveTo) {
          case "download":
            await downloadPng(component, chartTitle);
            break;
          case "clipboard":
            await copyPngToClipboard(component);
            break;
          default:
            assertUnreachable(saveTo);
        }
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to download image",
          color: "red",
        });
      } finally {
        setScreenshotHeightPadding(0);
      }
    },
    [chartTitle],
  );

  const downloadSpreadsheet = useCallback(
    (format: "csv" | "xlsx") => {
      if (!queryData.downloadData) {
        return;
      }
      const subtitles = [
        `Category - ${dashboardFilters.category}`,
        `Roll - ${dashboardFilters.roll}`,
        `Audience - ${dashboardFilters.firstAudience}`,
        ...(dashboardFilters.secondAudience
          ? [`Secondary Audience - ${dashboardFilters.secondAudience}`]
          : []),
      ];
      const csvTables = genAllTablesInCsvFormat({
        data: queryData.downloadData,
        subtitle: subtitles.join(" | "),
      });
      if (!csvTables) {
        notifications.show({
          title: "Failed",
          message: "Failed to download file",
          color: "red",
        });
        return;
      }
      if (format === "csv") {
        downloadCsv(csvTables, chartTitle);
        return;
      }
      if (format === "xlsx") {
        downloadXlsx(csvTables, chartTitle);
        return;
      }

      assertUnreachable(format);
    },
    [chartTitle, dashboardFilters, queryData.downloadData],
  );

  return (
    <div className="flex flex-row w-screen h-full">
      <div
        className="flex flex-col h-full"
        style={{
          width: showAiSummary ? "67%" : "100%",
        }}
      >
        <DashboardTabHeader
          title={chartTitle}
          showAiSummary={() => setShowAiSummary(true)}
          downloadOptions={[
            {
              label: "Copy Chart to Clipboard",
              onClick: () => downloadImage("clipboard"),
            },
            {
              label: "Download Chart",
              onClick: () => downloadImage("download"),
            },
            {
              label: "Download Excel",
              onClick: () => downloadSpreadsheet("xlsx"),
            },
            {
              label: "Download CSV",
              onClick: () => downloadSpreadsheet("csv"),
            },
          ]}
          filterComponent={
            <StandardDashboardFilterBar
              key={metric}
              clientId={clientId}
              metric={metric}
              dashboardFilters={dashboardFilters}
              setDashboardFilters={setDashboardFilters}
            />
          }
        />
        <Tabs
          key={`${chartTitle}-${hash}-${dashboardFiltersVariables.category}`}
          h={`${height - NAVBAR_HEIGHT - DASHBOARD_TAB_HEADER_HEIGHT + screenshotHeightPadding}px`}
          w={"100%"}
          value={getCurrentSelectedTab()}
          onChange={(value) => value && setHash(value)}
          keepMounted={false}
          color="black"
        >
          <Tabs.List>
            {visualisations.map((vis) => (
              <Tabs.Tab key={vis.title} value={vis.title}>
                {vis.title}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {visualisations.map((vis) => {
            return (
              <Tabs.Panel
                ref={getCurrentSelectedTab() === vis.title ? ref : undefined}
                key={vis.title}
                value={vis.title}
              >
                <Suspense fallback={<LoadingSpinner />}>
                  {getVisualisationComponent(vis.component, {
                    dashBoardFilters: {
                      ...dashboardFiltersVariables,
                      metric: vis.metric || metric,
                    },
                    height:
                      height -
                      NAVBAR_HEIGHT -
                      DASHBOARD_TAB_HEADER_HEIGHT -
                      40 +
                      screenshotHeightPadding,
                    ...vis,
                    metric: vis.metric || metric,
                  })}
                </Suspense>
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </div>
      <Divider orientation="vertical" />
      <div
        style={{ display: showAiSummary ? undefined : "none", width: "33%" }}
        className="h-full"
      >
        <Suspense fallback={<SmallLoader />}>
          <AiSummary
            bodyText={aiSummary}
            dashBoardFilters={dashboardFiltersVariables}
            onClose={() => setShowAiSummary(false)}
          />
        </Suspense>
      </div>
    </div>
  );
};
