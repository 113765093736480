/**
 * @generated SignedSource<<2418a3f7e7ab0b39a5aae4cb9bc53165>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TimelineQuery$variables = {
  brand?: string | null | undefined;
  category?: string | null | undefined;
  clientId: string;
  firstAudience?: string | null | undefined;
  metric: string;
  roll?: number | null | undefined;
  secondAudience?: string | null | undefined;
  statement?: string | null | undefined;
};
export type TimelineQuery$data = {
  readonly allWaveDates: ReadonlyArray<{
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly brandFilterOptions: ReadonlyArray<{
    readonly BRAND: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly chartData: ReadonlyArray<{
    readonly BASE: number | null | undefined;
    readonly BRAND: string | null | undefined;
    readonly IS_SCORE: number | null | undefined;
    readonly PERCENTAGE: number | null | undefined;
    readonly STATEMENT: string | null | undefined;
    readonly WAVE_DATE: any | null | undefined;
  } | null | undefined> | null | undefined;
  readonly statementFilterOptions: ReadonlyArray<{
    readonly STATEMENT: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type TimelineQuery = {
  response: TimelineQuery$data;
  variables: TimelineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brand"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstAudience"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metric"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roll"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secondAudience"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statement"
},
v8 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v9 = {
  "kind": "Variable",
  "name": "AUDIENCE1",
  "variableName": "firstAudience"
},
v10 = {
  "kind": "Variable",
  "name": "AUDIENCE2",
  "variableName": "secondAudience"
},
v11 = {
  "kind": "Variable",
  "name": "BRAND_METRIC",
  "variableName": "metric"
},
v12 = {
  "kind": "Variable",
  "name": "CATEGORY",
  "variableName": "category"
},
v13 = {
  "kind": "Variable",
  "name": "ROLL",
  "variableName": "roll"
},
v14 = {
  "fields": [
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BRAND",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "STATEMENT",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "WAVE_DATE",
  "storageKey": null
},
v18 = [
  {
    "alias": "brandFilterOptions",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "BRAND"
        ]
      },
      (v14/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v15/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "statementFilterOptions",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "STATEMENT"
        ]
      },
      (v14/*: any*/)
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v16/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "allWaveDates",
    "args": [
      (v8/*: any*/),
      {
        "kind": "Literal",
        "name": "distinctSelect",
        "value": [
          "WAVE_DATE"
        ]
      },
      {
        "fields": [
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      (v17/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": "chartData",
    "args": [
      (v8/*: any*/),
      {
        "fields": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "Variable",
            "name": "BRAND",
            "variableName": "brand"
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "kind": "Variable",
            "name": "STATEMENT",
            "variableName": "statement"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "CollatedData",
    "kind": "LinkedField",
    "name": "collatedData",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BASE",
        "storageKey": null
      },
      (v15/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IS_SCORE",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PERCENTAGE",
        "storageKey": null
      },
      (v16/*: any*/),
      (v17/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimelineQuery",
    "selections": (v18/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimelineQuery",
    "selections": (v18/*: any*/)
  },
  "params": {
    "cacheID": "b12a84f30081f09e47a05c93c8b48a39",
    "id": null,
    "metadata": {},
    "name": "TimelineQuery",
    "operationKind": "query",
    "text": "query TimelineQuery(\n  $clientId: String!\n  $firstAudience: String\n  $secondAudience: String\n  $brand: String\n  $metric: String!\n  $category: String\n  $roll: Int\n  $statement: String\n) {\n  brandFilterOptions: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"BRAND\"]) {\n    BRAND\n  }\n  statementFilterOptions: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll}, distinctSelect: [\"STATEMENT\"]) {\n    STATEMENT\n  }\n  allWaveDates: collatedData(clientId: $clientId, filters: {BRAND_METRIC: $metric, CATEGORY: $category}, distinctSelect: [\"WAVE_DATE\"]) {\n    WAVE_DATE\n  }\n  chartData: collatedData(clientId: $clientId, filters: {AUDIENCE1: $firstAudience, AUDIENCE2: $secondAudience, BRAND: $brand, BRAND_METRIC: $metric, CATEGORY: $category, ROLL: $roll, STATEMENT: $statement}) {\n    BASE\n    BRAND\n    IS_SCORE\n    PERCENTAGE\n    STATEMENT\n    WAVE_DATE\n  }\n}\n"
  }
};
})();

(node as any).hash = "abc560ace25f9179a8e1fbc3437d846e";

export default node;
