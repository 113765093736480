import { LoadingOverlay } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../context/UserContext";
import { SuperUserRole } from "../types/Role";

export const DefaultPage: React.FC = () => {
  const { currentUser, selectedClient } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && selectedClient?.id) {
      navigate(`/dashboard/${selectedClient.id}/home`, {
        replace: true,
      });
      return;
    }
    if (currentUser && currentUser.role === SuperUserRole.SUPER_ADMIN) {
      navigate("/admin/clients", { replace: true });
      return;
    }
    if (currentUser === null) {
      navigate("/login", { replace: true });
    }
  }, [currentUser, navigate, selectedClient]);

  return <LoadingOverlay />;
};
