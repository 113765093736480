import { z } from "zod";

import { ClientWithUsersRole, clientConfigSchema } from "../types/Client";
import { NonSuperUserRole, SuperUserRole } from "../types/Role";
import { User } from "../types/User";
import { apiClient } from "./apiClient";

const clientsEndpointResponseSchema = z.object({
  clients: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      config: clientConfigSchema.optional().catch(undefined),
    }),
  ),
});

/**
 * Fetches all clients
 * Must be called with super admin permissions
 * @param accessToken
 * @returns a list of all clients
 */
export const getAllClients = async (
  user: User,
): Promise<ClientWithUsersRole[]> => {
  const clientsResponse = await apiClient.get("/clients");

  if (clientsResponse.status !== 200) {
    throw new Error("Failed to fetch clients");
  }

  const clientsData = clientsEndpointResponseSchema.safeParse(
    clientsResponse.data,
  );

  if (!clientsData.success) {
    return [];
  }

  if (
    clientsData.data.clients === undefined ||
    clientsData.data.clients.length === 0
  ) {
    return [];
  }

  return clientsData.data.clients.map((client): ClientWithUsersRole => {
    const configuredUserRoleForClient = user.clients.find(
      (usersClient) => usersClient.id === client.id,
    )?.role;
    const userRole = configuredUserRoleForClient || NonSuperUserRole.USER;
    return {
      ...client,
      role:
        user.role === SuperUserRole.SUPER_ADMIN
          ? NonSuperUserRole.ADMIN
          : userRole,
    };
  });
};
