import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useUser } from "../../context/UserContext";
import {
  CollatedData,
  getLatestWaveDate,
  getMaximumValue,
} from "../../util/dataProcessing";
import { genBrandCompareFn } from "../../util/genBrandCompareFn";
import { genBrandConfigs } from "../../util/genBrandConfigs";
import { getStatementOrderFn } from "../../util/getStatementOrderForClient";
import { Legend, LegendSeries } from "../Legend";
import {
  ScatterLineData,
  ScatterLineDataPoint,
  SingleScatterLine,
} from "./SingleScatterLine";
import { VisualisationFooter } from "./VisualisationFooter";
import { ScatterLineQuery as ScatterLineQueryType } from "./__generated__/ScatterLineQuery.graphql";
import { VisualisationComponent, VisualisationProps } from "./types";

const ScatterLineQuery = graphql`
  query ScatterLineQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

export const ScatterLine: VisualisationComponent = (
  props: VisualisationProps,
) => {
  const { dashBoardFilters, metric, height } = props;
  const { selectedClient } = useUser();

  const [latestWaveData, setLatestWaveData] = useState<CollatedData>();
  const [data, setData] = useState<
    ReadonlyArray<{ label: string; points: ScatterLineData }>
  >([]);
  const [legendSeries, setLegendSeries] = useState<ReadonlyArray<LegendSeries>>(
    [],
  );
  const [filtersAtLastUpdate, setFiltersAtLastUpdate] = useState<string>("");
  const [maxValue, setMaxValue] = useState<number>(100);

  const queryData = useLazyLoadQuery<ScatterLineQueryType>(ScatterLineQuery, {
    ...dashBoardFilters,
  });

  // Update the data when the filter changes
  useEffect(() => {
    const canonicalFilters = JSON.stringify(dashBoardFilters);
    if (
      !selectedClient?.config ||
      (filtersAtLastUpdate && filtersAtLastUpdate === canonicalFilters)
    ) {
      return;
    }
    const latestWaveDate = getLatestWaveDate(queryData.chartData, true);
    if (!latestWaveDate) {
      return;
    }
    const currentLatestWaveData = queryData.chartData?.filter(
      (data) => data?.WAVE_DATE === latestWaveDate,
    );
    setLatestWaveData(currentLatestWaveData);
    const sortFn = selectedClient?.config
      ? genBrandCompareFn(selectedClient.config)
      : undefined;
    const allBrands = latestWaveData
      ?.map((value) => value?.BRAND)
      // filter out duplicates
      ?.filter((value, index, self) => self.indexOf(value) === index)
      ?.filter((value): value is string => {
        return Boolean(value);
      })
      .sort(sortFn);
    if (!allBrands) {
      return;
    }
    const allBrandConfigs = genBrandConfigs(selectedClient.config, allBrands);
    const dataSeries = latestWaveData?.reduce(
      (acc, item) => {
        if (!item) {
          return acc;
        }
        const statement = item.STATEMENT;
        if (!statement || !item.PERCENTAGE || !item.BRAND) {
          return acc;
        }
        const statementData = acc[statement] || [];

        const brandColour = allBrandConfigs.find(
          (brandConfig) => brandConfig.name === item.BRAND,
        )?.colour;
        const legendSeriesValue = legendSeries.find(
          (legendSeriesToCheck) => legendSeriesToCheck.name === item.BRAND,
        );
        const shouldHighlight = selectedClient?.config?.clientBrands.some(
          (clientBrand) => clientBrand.name === item.BRAND,
        );
        const formattedDataPoint: ScatterLineDataPoint = {
          label: item.BRAND,
          value: item.PERCENTAGE,
          colour: brandColour || "#000000",
          isScore: !!item.IS_SCORE,
          shouldHighlight,
          enabled: legendSeriesValue ? legendSeriesValue.enabled : true,
        };
        return {
          ...acc,
          [statement]: [...statementData, formattedDataPoint],
        };
      },
      {} as Record<string, ScatterLineData>,
    );
    if (!dataSeries) {
      return;
    }
    const statementSortedSeries = Object.entries(dataSeries)
      .map(([statement, series]) => {
        return {
          label: statement,
          points: series,
        };
      })
      .sort((a, b) => {
        const statementOrderFn = getStatementOrderFn({
          clientConfig: selectedClient.config,
          metric: metric || dashBoardFilters.metric,
        });
        return statementOrderFn(a.label, b.label);
      });
    setData(statementSortedSeries);
    setMaxValue(getMaximumValue(latestWaveData) * 1.1);
    if (statementSortedSeries.length === 0) {
      return;
    }
    const sortedDataSeries = [...statementSortedSeries[0].points].sort(
      (a, b) => {
        const aIndex = allBrands?.indexOf(a.label);
        const bIndex = allBrands?.indexOf(b.label);
        if (aIndex === undefined || bIndex === undefined) {
          return 0;
        }
        return aIndex - bIndex;
      },
    );

    // Update the legend series
    const legendSeriesData: ReadonlyArray<LegendSeries> = sortedDataSeries.map(
      (series) => {
        const currentValue = legendSeries.find(
          (legendSeriesToCheck) => legendSeriesToCheck.name === series.label,
        );
        const enabled = currentValue ? currentValue.enabled : true;
        const brandColour = allBrandConfigs.find(
          (brandConfig) => brandConfig.name === series.label,
        )?.colour;
        return {
          name: series.label,
          colour: brandColour,
          enabled,
        };
      },
    );
    setLegendSeries(legendSeriesData);
    setFiltersAtLastUpdate(canonicalFilters);
  }, [
    dashBoardFilters,
    filtersAtLastUpdate,
    latestWaveData,
    legendSeries,
    metric,
    queryData.chartData,
    selectedClient?.config,
  ]);

  // Toggle the series on the chart
  const onToggleSeries = useCallback(
    (name: string) => {
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name === name) {
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      setLegendSeries(updatedLegendSeries);
      const updatedData = data.map(
        ({ label, points }) => {
          const updatedSeries = points.map((point) => {
            if (point.label === name) {
              return {
                ...point,
                enabled: !point.enabled,
              };
            }
            return point;
          });
          return {
            label,
            points: updatedSeries,
          };
        },
        {} as Record<string, ScatterLineData>,
      );
      setData(updatedData);
    },
    [data, legendSeries],
  );

  return (
    <div
      className="flex flex-col justify-end flex-start"
      style={{
        height,
      }}
    >
      <div className="flex justify-end h-24 mx-4 mb-4">
        {data.length > 0 && data[0].points.length > 1 && (
          <Legend
            series={legendSeries}
            onToggleSeries={onToggleSeries}
            marker="circle"
          />
        )}
      </div>
      <div className="flex flex-col w-full h-full overflow-y-auto">
        {data.map(({ label, points }) => (
          <SingleScatterLine
            key={label}
            label={label}
            points={points}
            scaleMaxValue={maxValue}
          />
        ))}
      </div>
      <VisualisationFooter
        dashboardFilters={dashBoardFilters}
        data={latestWaveData}
      />
    </div>
  );
};
