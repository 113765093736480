import { z } from "zod";

import { execSummaryConfigSchema } from "./ExecSummaryConfig";
import { userRoleSchema } from "./Role";

export enum VisualisationType {
  BRAND_EDGE_TRIANGLE = "Brand Edge Triangle",
  FUNNEL_COMPARISON = "Funnel Comparison",
  PERCENTAGE_STACK = "Percentage Stack",
  VERTICAL_BAR_GRAPH = "Vertical Bar Graph",
  STACKED_VERTICAL_BAR_GRAPH = "Stacked Vertical Bar Graph",
  SCATTER_LINE = "Scatter Line",
  TIMELINE = "Timeline",
  PROMPTED_MESSAGES = "Prompted Messages",
  UNPROMPTED_MESSAGES = "Unprompted Messages",
  NPS_COMMENTS = "NPS Comments",
}

export const visualisationTypeSchema = z.nativeEnum(VisualisationType);

export const brandConfigSchema = z.object({
  name: z.string(),
  colour: z.string().optional(),
});

export type BrandConfig = z.infer<typeof brandConfigSchema>;

export const statementOrderConfigSchema = z.object({
  metric: z.string(),
  order: z.array(z.string()),
});

export type StatementOrderConfig = z.infer<typeof statementOrderConfigSchema>;

export const visualisationConfigSchema = z.object({
  title: z.string(),
  metric: z.string().optional(),
  component: visualisationTypeSchema,
  colours: z.array(z.string()).optional(),
  options: z.unknown().optional(),
});

export type VisualisationConfig = z.infer<typeof visualisationConfigSchema>;

export const creativeEdgeTabSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  type: z.literal("creativeEdge"),
  aiSummaryText: z.string().optional(),
  topDiagnostics: z.array(z.string()).optional(),
});

export type CreativeEdgeTab = z.infer<typeof creativeEdgeTabSchema>;

export const standardTabConfigSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  type: z.literal("standard"),
  metric: z.string(),
  visualisations: z.array(visualisationConfigSchema),
  aiSummaryText: z.string().optional(),
});

export type StandardTabConfig = z.infer<typeof standardTabConfigSchema>;

export const tabConfigSchema = z.union([
  creativeEdgeTabSchema,
  standardTabConfigSchema,
]);

export type TabConfig = z.infer<typeof tabConfigSchema>;

export const clientConfigSchema = z.object({
  execSummary: execSummaryConfigSchema,
  clientBrands: z.array(brandConfigSchema),
  competitorBrands: z.array(brandConfigSchema),
  statementOrder: z.array(statementOrderConfigSchema),
  tabs: z
    .array(
      z.object({
        title: z.string(),
        items: z.array(tabConfigSchema),
      }),
    )
    .optional(),
});

export type ClientConfig = z.infer<typeof clientConfigSchema>;

export const clientSchema = z.object({
  id: z.string(),
  name: z.string(),
  config: clientConfigSchema.optional(),
});

export type Client = z.infer<typeof clientSchema>;

export const clientWithUsersRoleSchema = clientSchema.merge(
  z.object({
    role: userRoleSchema,
  }),
);

export type ClientWithUsersRole = z.infer<typeof clientWithUsersRoleSchema>;
