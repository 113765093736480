import { z } from "zod";

export enum SuperUserRole {
  SUPER_ADMIN = "SuperAdmin",
}

export const superUserRoleSchema = z.nativeEnum(SuperUserRole);

export enum NonSuperUserRole {
  ADMIN = "Admin",
  USER = "User",
}

export const nonSuperUserRoleSchema = z.nativeEnum(NonSuperUserRole);

export const userRoleSchema = superUserRoleSchema.or(nonSuperUserRoleSchema);
