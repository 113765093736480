// From the generated Relay types
export type CollatedData =
  | ReadonlyArray<
      | {
          readonly BASE: number | null | undefined;
          readonly BRAND: string | null | undefined;
          readonly IS_SCORE: number | null | undefined;
          readonly PERCENTAGE: number | null | undefined;
          readonly STATEMENT: string | null | undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          readonly WAVE_DATE: any | null | undefined;
        }
      | null
      | undefined
    >
  | null
  | undefined;

export type NoNullCollatedData = ReadonlyArray<
  NonNullable<NonNullable<CollatedData>[0]>
>;

/**
 * Gets the latest completed wave date
 * @returns the latest completed wave date in the format "YYYY-MM-DD HH:MM:SS.sss"
 */
export const genCurrentWaveDate = (): string => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const paddedMonth = month < 10 ? `0${month}` : month;
  const year = now.getFullYear();
  return `${year}-${paddedMonth}-01 00:00:00.000`;
};

/**
 * Gets the latest wave date from the given data
 * @param data the data to get the latest wave date from
 * @param completedOnly whether to only get the latest wave date if it is not for the current month
 * @returns the latest wave date in the format "YYYY-MM-DD HH:MM:SS.sss"
 */
export const getLatestWaveDate = (
  data: CollatedData,
  completedOnly?: boolean,
): string | null => {
  if (!data) {
    return null;
  }
  return (
    data.reduce((acc, curr) => {
      const now = new Date();
      const accDate = new Date(acc?.WAVE_DATE);
      const currDate = new Date(curr?.WAVE_DATE);
      // If we only want completed waves, check if the wave is NOT for the current month
      if (
        completedOnly &&
        currDate.getMonth() >= now.getMonth() &&
        currDate.getFullYear() >= now.getFullYear()
      ) {
        return acc;
      }

      if (!acc || !acc.WAVE_DATE) {
        return curr;
      }
      if (!curr || !curr.WAVE_DATE) {
        return acc;
      }

      return accDate > currDate ? acc : curr;
    }, null)?.WAVE_DATE || null
  );
};

/**
 * Gets the earliest wave date from the given data
 * @param data the data to get the earliest wave date from
 * @returns the earliest wave date in the format "YYYY-MM-DD HH:MM:SS.sss"
 */
export const getEarliestWaveDate = (data: CollatedData): string | null => {
  if (!data) {
    return null;
  }
  return (
    data.reduce((acc, curr) => {
      if (!acc || !acc.WAVE_DATE) {
        return curr;
      }
      if (!curr || !curr.WAVE_DATE) {
        return acc;
      }
      const accDate = new Date(acc.WAVE_DATE);
      const currDate = new Date(curr.WAVE_DATE);

      return accDate < currDate ? acc : curr;
    }, null)?.WAVE_DATE || null
  );
};

/**
 * Gets the data for the given wave date
 * @param data the data to get the wave date from
 * @param waveDate
 * @returns
 */
export const getDataForWaveDate = (
  data: CollatedData,
  waveDate: string,
): CollatedData => {
  if (!data) {
    return [];
  }
  const normaliseWave = (wave: string | Date): string => {
    const date = new Date(wave);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
    });
  };
  return data.filter(
    (d) => normaliseWave(d?.WAVE_DATE) === normaliseWave(waveDate),
  );
};

/**
 * Offsets the given wave date by the given number of months in the past
 * @param waveDate the wave date to offset
 * @param offsetByMonths the number of months in the past to offset by
 * @returns the offset wave date in the format "YYYY-MM-DD HH:MM:SS.sss"
 */
export const offsetWaveDate = (
  waveDate: string | Date,
  offsetByMonths: number,
): string => {
  const date = new Date(waveDate);
  date.setMonth(date.getMonth() - offsetByMonths);
  const monthValue = `${date.getMonth() + 1}`.padStart(2, "0");
  return `${date.getFullYear()}-${monthValue}-01 00:00:00.000`;
};

export const roundValueForDisplay = (value: number): number => {
  if (Math.abs(value) < 1) {
    return parseFloat(value.toFixed(1));
  }
  return Math.round(value);
};

/**
 * Collates the data by the given field
 * @param data the data to collate
 * @returns an object with the data collated by the selected field
 */
export const splitByField = (
  data: CollatedData,
  field: keyof NoNullCollatedData[0],
): Record<string, CollatedData> => {
  if (!data) {
    return {};
  }
  return data.reduce(
    (acc, curr) => {
      if (!curr || !curr[field]) {
        return acc;
      }
      if (Array.isArray(acc[curr[field]]) === false) {
        acc[curr[field]] = [];
      }
      const currentBrandData = acc[curr[field]];
      if (Array.isArray(currentBrandData)) {
        acc[curr[field]] = [...currentBrandData, curr].sort();
      }
      return acc;
    },
    {} as Record<string, CollatedData>,
  );
};

/**
 * Gets the maximum percentage value from the given data
 * @param data the data to get the maximum percentage value from
 * @returns the maximum percentage value
 */
export const getMaximumValue = (data: CollatedData): number => {
  if (!data) {
    return 0;
  }
  return data.reduce((acc, dataPoint) => {
    if (!dataPoint?.PERCENTAGE) {
      return acc;
    }
    return dataPoint.PERCENTAGE > acc ? dataPoint.PERCENTAGE : acc;
  }, 0);
};
