import { Divider, Text } from "@mantine/core";

import { FontFamily } from "../util/fontFamily";
import { FunnelConversionIndicator } from "./FunnelConversionIndicator";
import { HorizontalPercentageBar } from "./Visualisation/HorizontalPercentageBar";

type ConversionDataPoint = {
  readonly percentage: number;
  readonly showConversion?: boolean;
} | null;

type ConversionFunnelProps = {
  brand: string;
  boldTitle?: boolean;
  data: ReadonlyArray<ConversionDataPoint>;
  colour: string;
};

/**
 * A component that displays a conversion funnel as a series of horizontal percentage bars
 * with optional conversion indicators.
 *
 * @param props the brand, data, colour and boldTitle of the funnel
 * @returns the conversion funnel component
 */
export const ConversionFunnel: React.FC<ConversionFunnelProps> = (
  props: ConversionFunnelProps,
) => {
  const { brand, boldTitle, data, colour } = props;
  const funnelLines = data.map((dataPoint, statementIndex) => {
    if (!dataPoint) {
      // if there is no data point, return an empty div
      return (
        <div key={statementIndex} className="h-full">
          <div className="h-[37px]" />
          <Divider />
        </div>
      );
    }
    const nextStatementDataPoint =
      data.length > statementIndex + 1 ? data[statementIndex + 1] : null;
    const nextStepConversion =
      dataPoint?.percentage && nextStatementDataPoint?.percentage
        ? (nextStatementDataPoint.percentage / dataPoint.percentage) * 100
        : null;

    const barColour = colour;
    const percentage = dataPoint.percentage;
    const value = percentage * 100;

    return (
      <div key={statementIndex} className="w-full">
        {nextStepConversion &&
          (statementIndex === 1 || statementIndex === 2) && (
            <FunnelConversionIndicator
              percentage={nextStepConversion}
              offset={value * 2.5 + 50}
            />
          )}
        <HorizontalPercentageBar
          colour={barColour}
          value={value}
          compact={true}
          alignment={"center"}
          scaleBar={0.5}
        />
        <Divider />
      </div>
    );
  });

  return (
    <div key={brand} className="flex flex-col w-full">
      <div className="flex w-full h-full max-h-16 min-h-16">
        <Text
          mt={28}
          ta={"center"}
          w={"100%"}
          ff={
            boldTitle
              ? FontFamily.PPNeueMontrealBold
              : FontFamily.PPNeueMontrealMedium
          }
        >
          {brand}
        </Text>
      </div>
      <Divider />
      {funnelLines}
    </div>
  );
};
