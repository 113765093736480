import { Divider } from "@mantine/core";
import graphql from "babel-plugin-relay/macro";
import { useEffect, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { ChangeSince, changeSinceToMonths } from "../../util/changeSince";
import {
  CollatedData,
  getDataForWaveDate,
  getEarliestWaveDate,
  getLatestWaveDate,
  offsetWaveDate,
} from "../../util/dataProcessing";
import { HorizontalPercentageBar } from "./HorizontalPercentageBar";
import { PercentageStackQuery as PercentageStackQueryType } from "./__generated__/PercentageStackQuery.graphql";
import { VisualisationProps } from "./types";

const PercentageStackQuery = graphql`
  query PercentageStackQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $metric: String!
    $category: String
    $roll: Int
  ) {
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

type PercentageStackProps = {
  readonly brand: string;
  readonly changeSince: ChangeSince;
  readonly colours: string[];
  readonly isSecondary?: boolean;
  readonly allStatements?: ReadonlyArray<string>;
  readonly updateData: (data: CollatedData) => void;
  readonly forceCompact?: boolean;
} & Pick<VisualisationProps, "dashBoardFilters">;

export const PercentageStack: React.FC<PercentageStackProps> = (
  props: PercentageStackProps,
) => {
  const {
    brand,
    changeSince,
    colours,
    dashBoardFilters,
    isSecondary,
    allStatements,
    updateData,
    forceCompact,
  } = props;

  const queryData = useLazyLoadQuery<PercentageStackQueryType>(
    PercentageStackQuery,
    {
      brand,
      ...dashBoardFilters,
    },
  );

  const latestWaveDate = useMemo(() => {
    const latestCompleteWaveDate = getLatestWaveDate(queryData.chartData, true);
    const latestIncompleteWaveDate = getLatestWaveDate(queryData.chartData);
    return latestCompleteWaveDate || latestIncompleteWaveDate;
  }, [queryData.chartData]);

  const latestWaveData = useMemo(() => {
    if (!latestWaveDate) {
      return;
    }
    return getDataForWaveDate(queryData.chartData, latestWaveDate);
  }, [latestWaveDate, queryData.chartData]);

  useEffect(() => {
    updateData(latestWaveData);
  }, [latestWaveData, updateData]);

  const comparisonWaveData = useMemo(() => {
    if (!latestWaveDate) {
      return;
    }
    const getWaveToCompare = (changeSince: ChangeSince) => {
      const offsetByMonths = changeSinceToMonths[changeSince];
      if (offsetByMonths === 0) {
        return getEarliestWaveDate(queryData.chartData);
      }
      return offsetWaveDate(latestWaveDate, offsetByMonths);
    };
    const waveToCompare = getWaveToCompare(changeSince);
    if (!waveToCompare) {
      return;
    }
    return getDataForWaveDate(queryData.chartData, waveToCompare);
  }, [changeSince, latestWaveDate, queryData.chartData]);

  const compact = useMemo<boolean>(() => {
    if (forceCompact) {
      return true;
    }
    return allStatements ? allStatements.length > 6 : false;
  }, [allStatements, forceCompact]);

  const barConfigs = useMemo(() => {
    return allStatements?.map((statement, index) => {
      const data = latestWaveData?.find(
        (value) => value?.STATEMENT === statement,
      );
      const title = isSecondary ? "" : statement || "N/A";
      const barColour = isSecondary ? `${colours[index]}88` : colours[index];
      const valueFromEarlierWave = comparisonWaveData?.find(
        (value) => value?.STATEMENT === data?.STATEMENT,
      )?.PERCENTAGE;
      const changeFromEarlierWave =
        valueFromEarlierWave !== null &&
        valueFromEarlierWave !== undefined &&
        data?.PERCENTAGE
          ? data.PERCENTAGE - valueFromEarlierWave
          : 0;
      const percentage = data?.PERCENTAGE || 0;
      const value = data?.IS_SCORE ? percentage : percentage * 100;
      const changeValue = data?.IS_SCORE
        ? changeFromEarlierWave
        : changeFromEarlierWave * 100;

      return {
        title,
        barColour,
        value,
        changeValue,
        isSignificantChange: Math.abs(changeValue) > 3,
      };
    });
  }, [allStatements, colours, comparisonWaveData, isSecondary, latestWaveData]);

  return (
    <div className="flex flex-col flex-grow h-full">
      <Divider />
      {barConfigs?.map((barConfig, index) => {
        const { title, barColour, value, changeValue, isSignificantChange } =
          barConfig;
        return (
          <div key={index}>
            {!compact && <div className="h-1" />}
            <HorizontalPercentageBar
              title={title}
              colour={barColour}
              value={value}
              key={index}
              changeValue={changeValue}
              isSignificantChange={isSignificantChange}
              alignment={isSecondary ? "right" : "left"}
              compact={compact}
            />
            {!compact && <div className="h-1" />}
            <Divider />
          </div>
        );
      })}
    </div>
  );
};
