import graphql from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useUser } from "../../context/UserContext";
import { BrandConfig } from "../../types/Client";
import { ArrayElement } from "../../types/Util";
import { roundValueForDisplay } from "../../util/dataProcessing";
import {
  DateRange,
  dateRangeToMonths,
  dateToDateRanges,
} from "../../util/dateRange";
import { genBrandCompareFn } from "../../util/genBrandCompareFn";
import { genBrandConfigs } from "../../util/genBrandConfigs";
import { getChartSeriesFromCollatedData } from "../../util/getChartSeriesFromCollatedData";
import { getStatementOrderFn } from "../../util/getStatementOrderForClient";
import { sortByOrderThenAlphabeticalFn } from "../../util/sort";
import { valueFormatter } from "../../util/valueFormatter";
import { GraphChart } from "../GraphChart";
import { Legend, LegendSeries } from "../Legend";
import { FilterComponentProps, MultiFilterBar } from "../MultiFilterBar";
import { VisualisationFooter } from "./VisualisationFooter";
import { TimelineQuery as TimelineQueryType } from "./__generated__/TimelineQuery.graphql";
import { VisualisationComponent, VisualisationProps } from "./types";

export type TimelineChartData = TimelineQueryType["response"]["chartData"];

export type TimelineCollatedDataValue = NonNullable<
  ArrayElement<TimelineChartData>
>;

type QueryDataFields = keyof TimelineCollatedDataValue;

const TimelineQuery = graphql`
  query TimelineQuery(
    $clientId: String!
    $firstAudience: String
    $secondAudience: String
    $brand: String
    $metric: String!
    $category: String
    $roll: Int
    $statement: String
  ) {
    brandFilterOptions: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
      distinctSelect: ["BRAND"]
    ) {
      BRAND
    }
    statementFilterOptions: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
      }
      distinctSelect: ["STATEMENT"]
    ) {
      STATEMENT
    }
    allWaveDates: collatedData(
      clientId: $clientId
      filters: { BRAND_METRIC: $metric, CATEGORY: $category }
      distinctSelect: ["WAVE_DATE"]
    ) {
      WAVE_DATE
    }
    chartData: collatedData(
      clientId: $clientId
      filters: {
        AUDIENCE1: $firstAudience
        AUDIENCE2: $secondAudience
        BRAND: $brand
        BRAND_METRIC: $metric
        CATEGORY: $category
        ROLL: $roll
        STATEMENT: $statement
      }
    ) {
      BASE
      BRAND
      IS_SCORE
      PERCENTAGE
      STATEMENT
      WAVE_DATE
    }
  }
`;

/**
 * A component that displays data for the current tab
 *
 * @param props the data to be displayed
 * @returns a data visualisation component
 */
export const Timeline: VisualisationComponent = (props: VisualisationProps) => {
  const { dashBoardFilters, metric, height } = props;
  const { selectedClient } = useUser();

  const [chartId, setChartId] = useState<string>("timeline-chart");
  const [legendSeries, setLegendSeries] = useState<ReadonlyArray<LegendSeries>>(
    [],
  );
  const [dataAtLastLegendUpdate, setDataAtLastLegendUpdate] =
    useState<ApexAxisChartSeries>([]);
  const [statementOptions, setStatementOptions] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [filters, setFilters] = useState<FilterComponentProps[]>([]);
  const [filtersAtLastUpdate, setFiltersAtLastUpdate] = useState<string>("");
  const [filterBy, setFilterBy] = useState<QueryDataFields>("BRAND");
  const [filterValue, setFilterValue] = useState<string>("none");

  const [colourOptions] = useState<string[]>([
    "#FFAAD2",
    "#4BA0CD",
    "#929E97",
    "#BD5E8D",
    "#D34B28",
    "#D8AE96",
    "#E1E145",
    "#87A13D",
    "#CFDBB5",
    "#8280D4",
    "#C2962C",
  ]);

  const queryData = useLazyLoadQuery<TimelineQueryType>(TimelineQuery, {
    ...dashBoardFilters,
    brand: filterBy === "BRAND" ? filterValue : null,
    statement: filterBy === "STATEMENT" ? filterValue : null,
  });

  // Update the brand options when the data changes
  const brandOptions: ReadonlyArray<BrandConfig> = useMemo(() => {
    if (!queryData.brandFilterOptions || !selectedClient?.config) {
      return [];
    }
    const sortFn = genBrandCompareFn(selectedClient.config);
    const sortedBrands = queryData.brandFilterOptions
      ?.map((option) => option?.BRAND)
      ?.filter((option): option is string => {
        return Boolean(option);
      })
      .sort(sortFn);
    const allBrandConfigs = genBrandConfigs(
      selectedClient.config,
      sortedBrands,
    );
    return allBrandConfigs.filter((brandConfig) =>
      sortedBrands.includes(brandConfig.name),
    );
  }, [queryData.brandFilterOptions, selectedClient?.config]);

  const dateRangeOptions = useMemo(() => {
    if (!queryData.allWaveDates) {
      return [];
    }
    const allWaveDates = queryData.allWaveDates
      .filter((waveDate) => waveDate?.WAVE_DATE)
      .map((waveDate) => new Date(waveDate?.WAVE_DATE));
    const earliestDate = new Date(
      Math.min(...allWaveDates.map((date) => date.getTime())),
    );
    const validDateRanges = dateToDateRanges(earliestDate);
    const defaultDateRange = validDateRanges.includes(DateRange.TWELVE_MONTHS)
      ? DateRange.TWELVE_MONTHS
      : DateRange.ALL_TIME;
    setDateRange(defaultDateRange);
    return validDateRanges;
  }, [queryData.allWaveDates]);

  const containsForecast = useMemo(() => {
    return (
      queryData.allWaveDates?.some((waveDate) => {
        const now = new Date();
        const waveDateDate = new Date(waveDate?.WAVE_DATE);
        return (
          now.getFullYear() === waveDateDate.getFullYear() &&
          now.getMonth() === waveDateDate.getMonth()
        );
      }) || false
    );
  }, [queryData.allWaveDates]);

  const waveDatesInDateRange = useMemo(() => {
    if (!dateRange || !queryData.allWaveDates) {
      return [];
    }
    if (dateRange === DateRange.ALL_TIME) {
      return queryData.allWaveDates
        .filter((waveDate) => waveDate?.WAVE_DATE)
        .map((waveDate) => new Date(waveDate?.WAVE_DATE))
        .sort((a, b) => a.getTime() - b.getTime());
    }
    const now = new Date();
    const forecastBuffer = containsForecast ? 1 : 0;
    const dateRangeMonths = dateRangeToMonths[dateRange] + forecastBuffer;
    const cutoffDate = new Date(now);
    cutoffDate.setMonth(now.getMonth() - dateRangeMonths);
    return queryData.allWaveDates
      .filter((waveDate) => {
        if (!waveDate?.WAVE_DATE) {
          return false;
        }
        const waveDateDate = new Date(waveDate.WAVE_DATE);
        return waveDateDate >= cutoffDate;
      })
      .map((waveDate) => new Date(waveDate?.WAVE_DATE))
      .sort((a, b) => a.getTime() - b.getTime());
  }, [containsForecast, dateRange, queryData.allWaveDates]);

  const labels = useMemo(() => {
    return waveDatesInDateRange.map((date) => date.toISOString());
  }, [waveDatesInDateRange]);

  // Update the data when the filter changes
  const data: ApexAxisChartSeries = useMemo(() => {
    if (
      !filterValue ||
      !filterBy ||
      !queryData.chartData ||
      !selectedClient?.config ||
      waveDatesInDateRange.length === 0
    ) {
      return [];
    }
    const dataForDateRange = queryData.chartData.filter((data) => {
      if (!data?.WAVE_DATE) {
        return false;
      }
      return labels.indexOf(new Date(data.WAVE_DATE).toISOString()) !== -1;
    });
    const dataSeries = getChartSeriesFromCollatedData({
      queryData: dataForDateRange,
      splitSeriesBy: filterBy === "STATEMENT" ? "BRAND" : "STATEMENT",
      waveLabels: labels,
    });
    return dataSeries.sort((a, b) => {
      if (filterBy === "BRAND") {
        const statementSortFn = getStatementOrderFn({
          clientConfig: selectedClient.config,
          metric: metric || dashBoardFilters.metric,
        });

        return statementSortFn(a.name, b.name);
      }
      return (
        brandOptions.findIndex((brand) => brand.name === a.name) -
        brandOptions.findIndex((brand) => brand.name === b.name)
      );
    });
  }, [
    brandOptions,
    dashBoardFilters.metric,
    filterBy,
    filterValue,
    labels,
    metric,
    queryData.chartData,
    selectedClient?.config,
    waveDatesInDateRange.length,
  ]);

  // Calculate the y-axis limits based on the data
  const yAxisLimits = useMemo(() => {
    let maxValueInData = 0;
    let isNegativeValues = false;
    queryData.chartData?.forEach((data) => {
      if (data?.PERCENTAGE) {
        if (data.PERCENTAGE < 0) {
          isNegativeValues = true;
        }
        const adjustedPercentage = data.IS_SCORE
          ? data.PERCENTAGE
          : data.PERCENTAGE * 100;
        maxValueInData = Math.max(maxValueInData, Math.abs(adjustedPercentage));
      }
    });
    const maxValue = maxValueInData * 1.1;
    return { minValue: isNegativeValues ? -maxValue : 0, maxValue };
  }, [queryData.chartData]);

  const isScoreData = useMemo(() => {
    return queryData.chartData?.some((data) => data?.IS_SCORE) || false;
  }, [queryData.chartData]);

  // Update the statement options when the data changes
  useEffect(() => {
    if (!queryData.statementFilterOptions || statementOptions?.length > 0) {
      return;
    }
    const statementOrder = selectedClient?.config?.statementOrder.find(
      (config) => config.metric === metric,
    )?.order;
    const statementFilterOptions = queryData.statementFilterOptions
      ?.map((option) => option?.STATEMENT)
      ?.filter((option): option is string => {
        return Boolean(option);
      })
      .sort(sortByOrderThenAlphabeticalFn(statementOrder));
    setStatementOptions(statementFilterOptions);
  }, [
    metric,
    queryData.statementFilterOptions,
    selectedClient?.config,
    statementOptions,
  ]);

  // Update the legend series when the data changes
  useEffect(() => {
    if (data === dataAtLastLegendUpdate) {
      return;
    }
    const legendSeriesData: ReadonlyArray<LegendSeries> = data.map(
      (series, index) => {
        const currentValue = legendSeries.find(
          (legendSeriesToCheck) => legendSeriesToCheck.name === series?.name,
        );
        const enabled = currentValue ? currentValue.enabled : true;
        const colour =
          brandOptions?.find((brand) => brand.name === series.name)?.colour ||
          colourOptions[index % colourOptions.length];
        return {
          name: series.name || "",
          colour,
          enabled,
        };
      },
    );

    setLegendSeries(legendSeriesData);
    setDataAtLastLegendUpdate(data);
  }, [
    brandOptions,
    colourOptions,
    data,
    dataAtLastLegendUpdate,
    legendSeries,
    queryData.chartData,
  ]);

  const lockFilterByTo = useMemo(() => {
    if (queryData.brandFilterOptions?.length === 1) {
      setFilterBy("BRAND");
      setFilterValue(queryData.brandFilterOptions[0]?.BRAND || "none");
      return "BRAND";
    }
    if (queryData.statementFilterOptions?.length === 1) {
      setFilterBy("STATEMENT");
      setFilterValue(queryData.statementFilterOptions[0]?.STATEMENT || "none");
      return "STATEMENT";
    }
    return undefined;
  }, [queryData.brandFilterOptions, queryData.statementFilterOptions]);

  // Update the filters when the select options change
  useEffect(() => {
    const canonicalFilters = `${filterBy}-${filterValue}-${dateRange}-${dateRangeOptions}-${Object.values(dashBoardFilters)}`;
    if (
      canonicalFilters === filtersAtLastUpdate &&
      filterBy &&
      filterValue &&
      dateRange
    ) {
      return;
    }
    // If the filterBy is locked, do not show the filterBy toggle
    const filterByOptions: FilterComponentProps[] = lockFilterByTo
      ? []
      : [
          {
            variant: "segmentedControl",
            inputLabel: "View",
            options: ["Brand", "Metric"],
            value: filterBy,
            onSelect: (option) => {
              switch (option) {
                case "Brand":
                  setFilterBy("BRAND");
                  setFilterValue(
                    brandOptions && brandOptions.length > 0
                      ? brandOptions[0].name
                      : "none",
                  );
                  break;
                case "Metric":
                  setFilterBy("STATEMENT");
                  setFilterValue(
                    statementOptions && statementOptions.length > 0
                      ? statementOptions[0]
                      : "none",
                  );
                  break;
                default:
                  break;
              }
              setChartId(`${filterBy}-timeline-chart`);
              setFilterValue("none");
            },
          },
        ];
    const newFilters: FilterComponentProps[] = [
      ...filterByOptions,
      {
        variant: "dropdown",
        inputLabel: filterBy === "BRAND" ? "Brand" : "Metric",
        options:
          (filterBy === "BRAND"
            ? brandOptions?.map((option) => option.name)
            : statementOptions) || [],
        value: filterValue,
        onSelect: (option) => {
          setFilterValue(option || "none");
        },
      },
      {
        variant: "dropdown",
        inputLabel: "Date Range",
        options: dateRangeOptions,
        value: dateRange,
        onSelect: (option) => {
          if (
            option &&
            Object.values(DateRange).includes(option as DateRange)
          ) {
            setDateRange(option as DateRange);
          }
        },
        autoSelect: false,
      },
    ];
    setFilters(newFilters);
    if (dateRangeOptions !== undefined && dateRangeOptions.length > 0) {
      setFiltersAtLastUpdate(canonicalFilters);
    }
  }, [
    brandOptions,
    dashBoardFilters,
    dateRange,
    dateRangeOptions,
    filterBy,
    filterValue,
    filtersAtLastUpdate,
    lockFilterByTo,
    statementOptions,
  ]);

  const chartMarkers: ApexDiscretePoint[] = useMemo(() => {
    return data
      .map((series, seriesIndex) => {
        return series.data.map(
          (dataPoint, dataPointIndex): ApexDiscretePoint | null => {
            const validDataPointBefore =
              dataPointIndex > 0 && !!series.data[dataPointIndex - 1];
            const validDataPointAfter =
              dataPointIndex !== series.data.length - 1 &&
              !!series.data[dataPointIndex + 1];
            const isPartOfLine = validDataPointBefore || validDataPointAfter;
            if (dataPoint === null || dataPoint === undefined || isPartOfLine) {
              return null;
            }
            const seriesColour =
              legendSeries.find(
                (legendSeries) => legendSeries.name === series.name,
              )?.colour || "#000000";
            return {
              seriesIndex,
              dataPointIndex,
              size: 2,
              fillColor: seriesColour,
              strokeColor: seriesColour,
            };
          },
        );
      })
      .flat()
      .filter((config): config is ApexDiscretePoint => config !== null);
  }, [data, legendSeries]);

  // Toggle the series on the chart
  const onToggleSeries = useCallback(
    (name: string) => {
      const chart = ApexCharts.getChartByID(chartId);
      if (!chart) {
        return;
      }
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name === name) {
          series.enabled ? chart.hideSeries(name) : chart.showSeries(name);
          return {
            ...series,
            enabled: !series.enabled,
          };
        }
        return series;
      });
      // If all series are disabled, enable all series
      if (updatedLegendSeries.every((series) => !series.enabled)) {
        const allEnabledLegends = updatedLegendSeries.map((series) => {
          chart.showSeries(series.name);
          return {
            ...series,
            enabled: true,
          };
        });
        setLegendSeries(allEnabledLegends);
        return;
      }
      setLegendSeries(updatedLegendSeries);
    },
    [chartId, legendSeries],
  );

  // Show only the given series on the chart
  const onShowOnlySeries = useCallback(
    (name: string) => {
      const chart = ApexCharts.getChartByID(chartId);
      if (!chart) {
        return;
      }
      const updatedLegendSeries = legendSeries.map((series) => {
        if (series.name !== name) {
          chart.hideSeries(name);
          return {
            ...series,
            enabled: false,
          };
        }
        return series;
      });
      setLegendSeries(updatedLegendSeries);
    },
    [chartId, legendSeries],
  );

  return (
    <div
      className="flex flex-col justify-between w-full flex-start"
      style={{ height }}
    >
      <div className="flex flex-row h-[100px]">
        <MultiFilterBar filters={filters} />
        <div className="flex mr-4 w-full h-[100px]">
          {data.length > 1 && (
            <Legend
              series={legendSeries}
              onToggleSeries={onToggleSeries}
              onShowOnlySeries={onShowOnlySeries}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <GraphChart
          key={chartId}
          series={data}
          height={`${height - 140}px`}
          disabledSeries={legendSeries
            .filter((series) => !series.enabled)
            .map((series) => series.name)}
          type="line"
          options={{
            chart: {
              background: "#ffffff",
              foreColor: "#000000",
              id: chartId,
              height: "100%",
              width: "100%",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            forecastDataPoints: { count: containsForecast ? 1 : 0 },
            labels: [...labels],
            dataLabels: {
              style: {
                colors: ["#000000"],
                fontSize: "18",
              },
              enabled: false,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: valueFormatter.PercentageRemoveDecimalWithIcon as any,
              offsetY: -5,
              background: {
                enabled: false,
              },
              enabledOnSeries: [0],
            },
            xaxis: {
              type: "datetime",
              labels: {
                style: {
                  colors: "#000000",
                },
                formatter:
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  valueFormatter.AllCapsThreeLetterMonthYearDate as any,
              },
              tickAmount: labels.length - 2,
              axisTicks: {
                show: false,
              },
            },
            colors: data.map((series) => {
              return (
                legendSeries.find(
                  (legendSeries) => legendSeries.name === series.name,
                )?.colour || "#000000"
              );
            }),
            stroke: {
              show: true,
              curve: "smooth",
              lineCap: "butt",
            },
            markers: {
              size: 0,
              discrete: chartMarkers,
            },
            grid: {
              show: true,
              borderColor: "#bbbbbb",
              padding: {
                top: 20,
                left: 40,
                bottom: 30,
              },
            },
            legend: {
              show: false,
            },
            yaxis: [
              {
                show: true,
                showForNullSeries: true,
                min: yAxisLimits.minValue,
                max: yAxisLimits.maxValue,
                labels: {
                  show: true,
                  minWidth: 0,
                  maxWidth: 160,
                  offsetX: -5,
                  padding: 40,
                  formatter: (value: number | null) => {
                    if (value === null) {
                      return "-";
                    }
                    const roundedValue = roundValueForDisplay(value).toString();
                    return isScoreData ? roundedValue : `${roundedValue}%`;
                  },
                },
              },
            ],
          }}
        />
      </div>
      <VisualisationFooter
        dashboardFilters={dashBoardFilters}
        data={queryData.chartData}
      />
    </div>
  );
};
